import { EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '../../../auth/authentication.service';
import { MatDialog } from '@angular/material';
import { ConfirmComponent } from '../../../dialogs/confirm/confirm.component';
import { Header } from '../../../models/content/header';
import { User } from '../../../models/user';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
var MWU_LOGO_URL = '/assets/img/mwu-basic.jpg';
var MME_LOGO = '/assets/img/MME-Logo.png';
var HeaderAgentComponent = /** @class */ (function () {
    function HeaderAgentComponent(auth, dialog, router) {
        var _this = this;
        this.auth = auth;
        this.dialog = dialog;
        this.router = router;
        this.canManageBranches = false;
        this.content = new Header();
        this.currentUser = new User();
        this.home_link = '/listings';
        this.isMenuOpened = false;
        this.showBrochure = false;
        this.canManageClients = false;
        this.canManageClientContacts = false;
        this.canViewDashboard = true;
        this.canViewReportsPage = false;
        this.isClientUserHome = false;
        this.canManageClientUsers = false;
        this.onContactUs = new EventEmitter();
        this.onMenuToggle = new EventEmitter();
        this.isCompanyConveyancer = false;
        this.isIndividualConveyancer = false;
        this.isIndividualConsumer = false;
        this.openUserMenu = false;
        this.isProxyUser = false;
        this.isAdmin = false;
        this.clientRouteActive = false;
        this.clientContactRouteActive = false;
        this.isClientUser = false;
        // hideConsumerInvitation = false;
        this.canViewMyProperties = false;
        this.canViewMwuDashboard = false;
        this.canAddListing = false;
        this.canReportViewConnells = false;
        this.canAttachListing = false;
        this.canClientRead = false;
        this.canClientUserRead = false;
        this.canViewConsumerForm = false;
        this.canClientContactRead = false;
        this.canReadTask = false;
        this.urlMyTasks = environment.vmc_webapp2_url + 'tasks/list';
        this.router.events.pipe(filter(function (e) { return e instanceof NavigationEnd; })).subscribe(function (event) {
            _this.clientContactRouteActive = event.url.startsWith('/client/contact-management') ||
                event.url.match(/\/clients\/[0-9]+\/contacts/) !== null;
            _this.clientRouteActive = event.url.startsWith('/clients') &&
                !_this.clientContactRouteActive;
        });
    }
    HeaderAgentComponent.prototype.getUrlHome = function () {
        if (this.canViewMyProperties) {
            return '/listings';
        }
        return this.router.url;
    };
    HeaderAgentComponent.prototype.ngOnInit = function () {
        var _this = this;
        document.body.addEventListener('mouseup', function (event) { return (_this.openUserMenu = false); }, true);
        this.router.events.subscribe(function (event) {
            _this.auth.can('view-my-properties').subscribe(function (can) {
                _this.canViewMyProperties = can;
            });
            _this.auth.can('view-mwu-dashboard').subscribe(function (can) { return (_this.canViewMwuDashboard = can); });
            _this.auth.can('add-listing').subscribe(function (can) { return (_this.canAddListing = can); });
            _this.auth.can('report-view-connells').subscribe(function (can) { return (_this.canReportViewConnells = can); });
            _this.auth.can('attach-listing').subscribe(function (can) { return (_this.canAttachListing = can); });
            _this.auth.can('client-read').subscribe(function (can) { return (_this.canClientRead = can); });
            _this.auth.can('client-user-read').subscribe(function (can) { return (_this.canClientUserRead = can); });
            _this.auth.can('view-consumer-form').subscribe(function (can) { return (_this.canViewConsumerForm = can); });
            _this.auth.can('client-contact-read').subscribe(function (can) { return (_this.canClientContactRead = can); });
            _this.auth.can('task-read').subscribe(function (can) { return (_this.canReadTask = can); });
        });
    };
    HeaderAgentComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        document.body.removeEventListener('mouseup', function (event) { return (_this.openUserMenu = false); }, true);
    };
    HeaderAgentComponent.prototype.toggleMenu = function () {
        this.onMenuToggle.emit();
    };
    HeaderAgentComponent.prototype.logout = function () {
        var _this = this;
        var modal = this.dialog.open(ConfirmComponent, { data: { question: 'Are you sure you want to log out?' } });
        modal.afterClosed().subscribe(function (result) {
            if (result) {
                _this.auth.logout();
            }
        });
    };
    HeaderAgentComponent.prototype.toggleUserMenu = function () {
        this.openUserMenu = !this.openUserMenu;
    };
    HeaderAgentComponent.prototype.showContact = function () {
        this.onContactUs.emit();
    };
    HeaderAgentComponent.prototype.ngOnChanges = function (changes) {
        this.isProxyUser = this.currentUser.attributes.type.toLowerCase() == 'proxy';
        this.isClientUser = this.currentUser.attributes.type.toLowerCase() == 'client_user';
        this.isAdmin = this.currentUser.attributes.type.toLowerCase() == 'admin';
    };
    Object.defineProperty(HeaderAgentComponent.prototype, "headerLogo", {
        get: function () {
            if (this.isClientUserHome && this.isClientUser) {
                var isMME = Boolean(this.auth.teams.find(function (team) { return team.attributes.branch_id == -1040; }));
                if (isMME) {
                    return MME_LOGO;
                }
                return MWU_LOGO_URL;
            }
            return this.content.headerLogo;
        },
        enumerable: true,
        configurable: true
    });
    HeaderAgentComponent.prototype.reloadPage = function (supportLink) {
        supportLink.blur();
        setTimeout(function () { return window.location.reload(); }, 100);
    };
    HeaderAgentComponent.prototype.redirectToMyTasks = function () {
        window.location.href = this.urlMyTasks;
    };
    HeaderAgentComponent.prototype.redirectToNewConsumerForm = function () {
        window.location.href = environment.vmc_webapp2_url + 'consumer/create';
    };
    HeaderAgentComponent.prototype.redirectToNewProfileView = function () {
        window.location.href = environment.vmc_webapp2_url + 'profile';
    };
    HeaderAgentComponent.prototype.redirectToNewListings = function () {
        window.location.href = environment.vmc_webapp2_url + 'listings';
    };
    HeaderAgentComponent.prototype.redirectToNewReports = function () {
        window.location.href = environment.vmc_webapp2_url + 'reports';
    };
    HeaderAgentComponent.prototype.redirectToCompanyConveyancerAttachListing = function () {
        window.location.href = environment.vmc_webapp2_url + 'attach-listing';
    };
    HeaderAgentComponent.prototype.redirectToProxyAttachListing = function () {
        window.location.href = environment.vmc_webapp2_url + 'proxy/attach-listing';
    };
    HeaderAgentComponent.prototype.redirectToNewAddListing = function () {
        window.location.href = environment.vmc_webapp2_url + 'listing/create';
    };
    HeaderAgentComponent.prototype.redirectToNewBranchPage = function () {
        window.location.href = environment.vmc_webapp2_url + 'branches';
    };
    HeaderAgentComponent.prototype.redirectToNewClients = function () {
        window.location.href = environment.vmc_webapp2_url + 'clients';
    };
    HeaderAgentComponent.prototype.redirectToNewClientUsers = function () {
        window.location.href = environment.vmc_webapp2_url + 'client-users';
    };
    HeaderAgentComponent.prototype.redirectToNewContactManagement = function () {
        window.location.href = environment.vmc_webapp2_url + 'client/contact-management';
    };
    return HeaderAgentComponent;
}());
export { HeaderAgentComponent };
