import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var TeamService = /** @class */ (function (_super) {
    tslib_1.__extends(TeamService, _super);
    function TeamService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TeamService.prototype.get = function (id, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'teams/' + id + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('TeamService::get, url=' + url + ', id=' + id, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    TeamService.prototype.updateName = function (id, name) {
        var url = this.url + 'settings/teams/' + id + '/name';
        var data = { name: name };
        return this.http.put(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('TeamService::updateName, url=' + url + ', name=' + name, {})));
    };
    TeamService.prototype.invite = function (id, email) {
        var url = this.url + 'settings/teams/' + id + '/invitations';
        return this.http.post(url, { email: email }, { headers: this.getHeaders() }).pipe(catchError(this.handleError('TeamService::invite, url=' + url + ', email=' + email, {})));
    };
    TeamService.prototype.getInvitation = function (token) {
        var url = this.url + 'invitations/' + token;
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('TeamService::getInvitation, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    TeamService.prototype.getInvitations = function (id) {
        var url = this.url + 'settings/teams/' + id + '/invitations';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('TeamService::filter, url=' + url + ', id=' + id, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    TeamService.prototype.deleteInvitation = function (id) {
        var url = this.url + 'settings/invitations/' + id;
        return this.http.delete(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('TeamService::filter, url=' + url + ', id=' + id, [])));
    };
    TeamService.prototype.deleteMember = function (team_id, member_id) {
        var url = this.url + 'settings/teams/' + team_id + '/members/' + member_id;
        return this.http.delete(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('TeamService::deleteMember, url=' + url + ', team_id=' + team_id + ', member_id=' + member_id, [])));
    };
    TeamService.prototype.acceptInvitation = function (id) {
        var url = this.url + 'settings/invitations/' + id + '/accept';
        return this.http.post(url, [], { headers: this.getHeaders() }).pipe(catchError(this.handleError('TeamService::acceptInvitation, url=' + url + ', id=' + id, [])));
    };
    TeamService.prototype.rejectInvitation = function (id) {
        var url = this.url + 'settings/invitations/' + id + '/reject';
        return this.http.post(url, [], { headers: this.getHeaders() }).pipe(catchError(this.handleError('TeamService::rejectInvitation, url=' + url + ', id=' + id, [])));
    };
    TeamService.prototype.members = function (id) {
        var url = this.url + 'team/' + id + '/relationships/users';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('TeamService::members, url=' + url + ', id=' + id, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    return TeamService;
}(ApiService));
export { TeamService };
